/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        //  FORMS - GESTION DU LABEL
        $('.wrap-form-elem').has('> label')
            .find('input[type="text"], input[type="number"], input[type="date"], input[type="email"], input[type="password"], input[type="tel"], textarea')
            .on('focus', function(){
                $(this).closest('.wrap-form-elem').addClass('hasFocus');
            }).on('blur', function(){
                $(this).closest('.wrap-form-elem').removeClass('hasFocus');
            });

        //  GESTION DE LA RECHERCHE PRINCIPALE
        if( ($('.js-pop-search').length > 0) && ($('.MainSearch').length > 0) ){
            $('.js-pop-search').on('click', function(e){
                e.preventDefault();
                $('.MainSearch').toggleClass('active');
                $('body').toggleClass('pop-active');
            });
            $('.MainSearch-close').on('click', function(e){
                e.preventDefault();
                $('.MainSearch').removeClass('active');
                $('body').removeClass('pop-active');
            });
        }
        $(document).keydown(function(e) {
            // ESCAPE key pressed
            if (e.keyCode == 27) {
                if( $('.MainSearch').hasClass('active') ){
                    $('.MainSearch').removeClass('active');
                    $('body').removeClass('pop-active');
                }
            }
        });

        //  GESTION DU MENU (accessibilite)

         $(".WrapMenu-nav").accessibleMegaMenu({
           menuClass: "MainNav",
           panelClass: "wrap-sub-menu",
           panelGroupClass: "sub-menu",
         });

        //  GESTION DU MENU (mobile et tablette)
        if( ($('.js-pop-menu').length > 0) && ($('.WrapMenu-nav').length > 0) ){
            $('.js-pop-menu').on('click', function(e){
                if( $(window).width() < 1024 ){
                    e.preventDefault();
                    $('.WrapMenu-nav').toggleClass('mob-active');
                    $(this).toggleClass('active');

                    if( $('.TopNav-nav').hasClass('mob-active') && $('.WrapMenu-nav').hasClass('mob-active') ){
                      $('.TopNav-nav').removeClass('mob-active');
                      $('.js-pop-tools').removeClass('active');
                    }
                }
            });
        }
        if( ($('.js-pop-tools').length > 0) && ($('.TopNav-nav').length > 0) ){
            $('.js-pop-tools').on('click', function(e){
                if( $(window).width() < 1024 ){
                    e.preventDefault();
                    $('.TopNav-nav').toggleClass('mob-active');
                    $(this).toggleClass('active');

                    if( $('.TopNav-nav').hasClass('mob-active') && $('.WrapMenu-nav').hasClass('mob-active') ){
                      $('.WrapMenu-nav').removeClass('mob-active');
                      $('.js-pop-menu').removeClass('active');
                    }
                }
            });
        }
        if( Modernizr.touchevents && $('.MainNav').length > 0 ){
          $('.MainNav > li.menu-item-has-children > a').click(function(e){
            if( !$(this).closest('li').hasClass('active') ){
              e.preventDefault();
              $('.MainNav li').removeClass('active');
              $(this).closest('li').addClass('active');
            }
          });
        }

        //  GESTION DU On vous rappelle (sidebar)
        if( $('.Sidebar .FormContact').length > 0 ){
          $('.Sidebar .FormContact-title').click(function(){
            $(this).toggleClass('active');
            $(this).closest('.FormContact').find('.FormContact-wrapForm').toggleClass('active');
          });
        }

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        $('.Slider').each(function(){
          var opt = {
            slide: '.Slider-slide',
            appendArrows: $(this).find('.Slider-controls'),
            prevArrow: '<button type="button" class="slick-prev"><</button>',
            nextArrow: '<button type="button" class="slick-next">></button>',
            responsive: [
              {
                breakpoint: 640,
                settings: {
                  slidesToShow: 1,
                  adaptiveHeight: true
                }
              }
            ]
          };
          if( $(this).hasClass('references') ){
            opt.variableWidth = true;
            opt.infinite = false;
            opt.responsive[0].settings.variableWidth = false;
          }
          $(this).slick(opt);
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'page_template_template_solution': {
      init: function() {
        $('.Slider').each(function(){
          var opt = {
            slide: '.Slider-slide',
            appendArrows: $(this).find('.Slider-controls'),
            prevArrow: '<button type="button" class="slick-prev"><</button>',
            nextArrow: '<button type="button" class="slick-next">></button>',
            infinite: true,
            autoplay: true,
            autoplaySpeed: 2000,
          };
          $(this).slick(opt);
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
